import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import TutorCard from './TutorCard'
import tutors from "./tutor";

export default function Tutors(props:{tutors:boolean}) {
  return (
    <>
    {Array(Math.ceil(tutors.length / 3))
        .fill(null)
        .map((_, rowIndex) => (
          <Row key={rowIndex} className="justify-content-start" style={{flexDirection:"row-reverse"}}>
            {tutors
              .slice(rowIndex * 3, (rowIndex + 1) * 3)
              .map((item, colIndex) => (
                (item.id!=="3" || !props.tutors)&&
                <Col
                  sm="12"
                  md="4"
                  className="d-flex justify-content-center"
                  key={colIndex}
                >
                  <TutorCard
                    id = {item.id.toString()}
                    img={process.env.PUBLIC_URL +item.img}
                    name={item.name}
                    description={item.description.slice(0,80)+"..."} 
                    levels = {["1"]}
                    selectedLevel = {0}
                    video = {!!item.video?process.env.PUBLIC_URL +item.video:undefined}
                  ></TutorCard>
                </Col>
              ))}
          </Row>
        ))}</>
  )
}
