import React from 'react';
import './Footer.css';

export default function Footer() {
  const scrollToComponent = (navto: string) => {
    const element = document.getElementById(navto);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className='footer-container'>
      <a href='https://wa.me/+491782059890' target='_blank'>
        Contact us
      </a>
      <span>|</span>
      <a onClick={() => scrollToComponent('wih-container')}>About us</a>
      <span>|</span>
      <a onClick={() => scrollToComponent('teachers-component-container')}>
        Courses
      </a>
    </div>
  );
}
