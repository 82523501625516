import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import TutorCard from './TutorCard';
import Filters from './Filters';
import Tutors from './Tutors';
import Overview from './Overview';

const LandingPage: React.FC = () => {
  const [removeSomeTutors, setRemoveSomeTutors] = useState(false);
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
        backgroundSize: 'cover',
      }}
    >
      <Overview></Overview>
    </div>
  );
};

export default LandingPage;
