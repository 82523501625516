import React from 'react';

const CircleProgressBar = ({ title, number }: any) => {
  const radius = 70; // Doubled the radius
  const stroke = 8;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (number / 10) * circumference;
  let strokeColor = '';
  if (number <= 4) {
    strokeColor = 'red';
  } else if (number > 4 && number <= 7) {
    strokeColor = 'yellow';
  } else {
    strokeColor = 'green';
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <h6>{title}</h6>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke='lightgrey'
          fill='transparent'
          strokeWidth={22}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={strokeColor}
          fill='transparent'
          strokeWidth={12}
          strokeDasharray={circumference + ' ' + circumference}
          style={{
            strokeDashoffset,
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <text
          x='50%'
          y='50%'
          dy='.3em' // Adjusts vertical alignment
          textAnchor='middle'
          style={{ fill: '#333', fontSize: '1.5em' }}
        >
          {number}/10
        </text>
      </svg>
    </div>
  );
};

export default CircleProgressBar;
