import React, { useEffect, useState } from 'react';
import './UniversityPage.css';
import { useParams } from 'react-router-dom';
import { universityType } from '../types/universityType';

import Rating from './Rating';
import Menu from './Menu';
import CircleProgressBar from './CircleProgressBar';
import Loading from './Loading';
import { baseUrl } from '../utils/Default';

export default function UniversityPage() {
  const regex = /[;.؛]/g;
  const { id } = useParams();
  const [university, setUniversity] = useState<null | any>(null);
  const handleApply = () => {
    window.location.href = `/application`;
  };
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetch(`${baseUrl}/api/university/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setUniversity(data);
        console.log(data);
      })
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation:',
          error
        );
      });
  }, []);

  return (
    <div
      className='university-page-container'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
      }}
    >
      <Menu home={false} />
      {university ? (
        <div className='university-page-inner-container'>
          <div className='logo-and-button'>
            <div className='uni-logo-name-location'>
              <img src={university!.img} alt='' />
              <div className='uni-name-rating-location'>
                <h1 className='uni-page-name'>{university!.DAAD_Name}</h1>
                <Rating rating={university!.review_score} />
                <h3 className='uni-page-location'>{university!.location}</h3>
              </div>
            </div>

            <button
              onClick={() => handleApply()}
              className='application-btn-page'
            >
              &lt;&lt; للتقديم
            </button>
          </div>

          <div className='uni-pros-and-cons' dir='rtl'>
            <div className='uni-pros' dir='rtl'>
              <h3>مميزات الجامعة</h3>
              <ul>
                {university!.elementPositive.map((element: string) => (
                  <li>{element.replace(regex, '')}</li>
                ))}
              </ul>
            </div>
            <div className='uni-cons' dir='rtl'>
              <h3>عيوب الجامعة</h3>
              <ul>
                {university!.elementNegative.map((element: string) => (
                  <li>{element.replace(regex, '')}</li>
                ))}
              </ul>
            </div>
          </div>
          <h3 style={{ fontSize: '19px' }} dir='rtl'>
            تقيمات المدينة
          </h3>
          <div className='uni-ratings' dir='rtl'>
            <CircleProgressBar
              title='توافر سكن للطابة'
              number={university!.housing_difficulty}
            />
            <CircleProgressBar
              title='تكاليف المعيشة'
              number={university!.cost_of_living}
            />
            <CircleProgressBar
              title='مناسبة للطلبة الأجانب'
              number={university!.foreign_student_suitability}
            />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
