import React, { useEffect, useRef, useState } from 'react';
import Rating from './Rating';
import './UniversitiesComponent.css';
import { baseUrl } from '../utils/Default';

import { Link } from 'react-router-dom';
import { Fields } from './fields';
import Loading from './Loading';
import axios from 'axios';
import RangeSlider from './RangeSlider';

const UniCard = ({ name, rating, imgLink, link, id }: any) => {
  return (
    <div className='uni-card'>
      <div className='uni-name-and-img'>
        <img src={imgLink}></img>
        <h6>{name}</h6>
      </div>
      <div className='uni-rating'>
        <label>Rating: {rating}</label>
        <Rating rating={rating}></Rating>
      </div>
      <div className='uni-buttons' dir='rtl'>
        <Link to={`university/${id}`}>
          <button className='uni-btn' style={{ background: '#090d2c' }}>
            اعرف المزيد
          </button>
        </Link>
        <Link to={link} target='_blank'>
          <button className='uni-btn' style={{ background: '#2a9d92' }}>
            موقع الجامعة
          </button>
        </Link>
      </div>
    </div>
  );
};
export default function UniversitiesComponent() {
  const [data, setData] = useState<any | null>([]);
  const [field, setField] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<any>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchComponentRef = useRef<any>(null); // Ref for the component
  const [studentWgRate, setStudentWgRate] = useState<number>(1);
  const [livingCostRate, setLivingCostRate] = useState<number>(1);
  const [studenSuitability, setStudenSuitability] = useState<number>(1);
  const [uniRateFilter, setUniRateFilter] = useState<number>(1);

  const handleStateChange1 = (newState: number) => {
    setStudentWgRate(newState);
  };
  const handleStateChange2 = (newState: number) => {
    setLivingCostRate(newState);
  };
  const handleStateChange3 = (newState: number) => {
    setStudenSuitability(newState);
  };

  const handleStateChange4 = (newState: number) => {
    setUniRateFilter(newState);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchComponentRef]);
  const handleApply = () => {
    window.location.href = `/application`;
  };
  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowSuggestions(true);

    if (value) {
      const filteredSuggestions = Fields.filter((str) =>
        str.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const fetchSortedUniversities = async (field: any) => {
    try {
      const response = await axios.get(`${baseUrl}/api/universities`, {
        headers: {
          field: field,
        },
      });

      setData(
        response.data.filter(
          (d: any) =>
            d.cost_of_living >= livingCostRate &&
            d.foreign_student_suitability >= studenSuitability &&
            d.housing_difficulty >= studentWgRate &&
            d.review_score >= uniRateFilter
        )
      );
    } catch (error) {
      console.error('Error fetching data: ', error);
      console.log(baseUrl);
      // Handle the error appropriately
    }
  };
  const handleSuggestionClick = (suggestion: any) => {
    setSearchTerm(suggestion);
    setShowSuggestions(false);
    // Optionally trigger the search immediately
    // handleSearchClick();
  };

  useEffect(() => {
    fetchSortedUniversities(searchTerm);
  }, []);
  return (
    <div
      className='universities-component-container'
      id='universities-component-container'
    >
      <h2 className='universities-component-title'>عايز تدرس ايه؟</h2>
      <div ref={searchComponentRef} style={{ width: '20rem' }}>
        <input
          type='text'
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setShowSuggestions(true)}
          placeholder='Search...'
          className='uni-search-input'
        />
        <button
          onClick={() => fetchSortedUniversities(searchTerm)}
          className='uni-search-button'
        >
          Search
        </button>
        {showSuggestions && suggestions.length > 0 && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              background: '#fff',
              height: '200px',
              width: '20rem',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {suggestions.map((suggestion: any, index: any) => (
              <div
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{ padding: '5px', cursor: 'pointer' }}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>

      <button onClick={() => handleApply()} className='application-btn'>
        &lt;&lt; للتقديم
      </button>

      <div
        className='universities-component-list-container'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
        }}
      >
        {data.length > 0 ? (
          data.map((uni: any) => (
            <UniCard
              key={uni.id}
              name={uni.DAAD_Name}
              rating={uni.review_score}
              id={uni.id}
              link={uni.link}
              imgLink={uni.img}
            />
          ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
