import React from 'react';
import './LogoScroller.css'; // Import the CSS file

const LogoScroller = () => {
  const iconList = [
    `${process.env.PUBLIC_URL}/images/pexels-abby-chung-1106468 1.png`,
    `${process.env.PUBLIC_URL}/images/pexels-ingo-joseph-21627 1.png`,
    `${process.env.PUBLIC_URL}/images/pexels-ingo-joseph-109629 1.png`,
    `${process.env.PUBLIC_URL}/images/pexels-lil-artsy-1925536 1.png`,
    `${process.env.PUBLIC_URL}/images/pexels-oleksandr-p-1008155 1.png`,
    `${process.env.PUBLIC_URL}/images/pexels-pixabay-46148 1.png`,
    `${process.env.PUBLIC_URL}/images/pexels-pixabay-247899 1.png`,
  ];

  return (
    <div className='scroller-container' id='scroller-container'>
      <h1 style={{ textAlign: 'center' }}>Coming soon</h1>
      <div className='scroller'>
        {iconList.map((item, index) => (
          <img
            key={index}
            src={item}
            alt={`logo-${index}`}
            className='scroller-img'
          />
        ))}
        {iconList.map((item, index) => (
          <img
            key={index + iconList.length}
            src={item}
            alt={`logo-${index}`}
            className='scroller-img'
          />
        ))}
      </div>
    </div>
  );
};

export default LogoScroller;
