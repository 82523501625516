export interface levelType{
    id:string,
    price:number,
    minNumberStudents?:number,
    maxNumberStudents?:number,
    duration?:number,
    complexity: Complexity,
    numSubLevels:number
}


export enum Complexity {
    A1 = "A1",
    A2="A2",
    B1="B1",
    B2="B2",
    C1="C2",
    C2="C2"
}