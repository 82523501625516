import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from 'react-router-dom';
import './Menu.css';
function Menu(props: { home: boolean }) {
  const navigate = useNavigate();

  const scrollToComponent = (navto: string) => {
    const element = document.getElementById(navto);
    if (props.home == false) navigate('/');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar
        expand='lg'
        style={{ color: '#fff', paddingLeft: '10px' }}
        className='menu'
      >
        <Link className='logo d-none d-lg-block' to='/'>
          <img
            src={`${process.env.PUBLIC_URL}/images/Frame_397.png`}
            className='logo d-none d-lg-block'
            style={{ height: '50px' }}
          ></img>
        </Link>
        <Link className='logo d-block d-lg-none' to='/'>
          <img
            src={`${process.env.PUBLIC_URL}/images/Frame_397.png`}
            className='logo d-block d-lg-none'
            style={{ height: '50px' }}
          ></img>
        </Link>

        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          className='custom-toggle-icon'
        />
        <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
          <Nav className='mr-auto' dir='rtl'>
            <button
              onClick={() => scrollToComponent('wih-container')}
              className='menu-btn'
            >
              معلومات عنا
            </button>
            <button
              className='menu-btn'
              onClick={() => scrollToComponent('teachers-component-container')}
            >
              احجز كورس
            </button>

            <button className='menu-btn'>
              <a
                href='https://wa.me/+491782059890?'
                target='_blank'
                className='menu-btn'
              >
                WhatsApp
              </a>
            </button>
            <button className='menu-btn'>
              <a
                href='https://www.instagram.com/hegra.tech/'
                target='_blank'
                className='menu-btn'
              >
                Instagram
              </a>
            </button>
            <button className='menu-btn'>
              <a
                href='mailto:mohamed.gamea@hegra.tech'
                target='_blank'
                className='menu-btn'
              >
                Email
              </a>
            </button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Menu;
