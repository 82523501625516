import React from 'react';
import './CalculationComponent.css';
import CalculationForm from './CalculateGermanGrade';

export default function CalculationComponent() {
  const width = window.innerWidth;
  return (
    <div
      className='calulation-component-container'
      id='calulation-component-container'
      dir='rtl'
    >
      <div dir='rtl' className='calculation-description'>
        <h1>احسب معدلك بالألماني</h1>
        <p>
          تقدر تحول مجموع الثانوية العامة للمعدل الألماني، ودا عن طريق إدخال أقل
          درجة للنجاح و أعلي درجة يمكن الحصول عليها و الدرجة التي حصلت عليها ثم
          إختيار مجال الدراسة المرغوب والضغط علي إحسب
        </p>
      </div>

      <div className='calculator'>
        <CalculationForm />
      </div>
    </div>
  );
}
