import React from 'react';

// Star component - Renders a single star
const Star = ({ isGold }: any) => (
  <span
    style={{
      color: isGold ? 'gold' : 'grey',
      fontSize: '30px',
      lineHeight: '30px',
    }}
  >
    ★
  </span>
);

// Rating component - Renders stars based on decimal rating
const Rating = ({ rating }: any) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    // Check if the star should be gold or grey
    // Star is gold if i is less than the rating rounded down or equal to the rating rounded to nearest half
    const isGold =
      i < Math.floor(rating) || (i === Math.floor(rating) && rating % 1 >= 0.5);
    stars.push(<Star key={i} isGold={isGold} />);
  }
  return <div>{stars}</div>;
};

export default Rating;
