import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { tutorType } from '../types/tutorType';
import { Link } from 'react-router-dom';

type tutorPropsType = tutorType & { selectedLevel: number };

function TutorCard(props: tutorPropsType) {
  const { img, name, description, id } = props;
  const BUTTON_TEXT = 'اعرف المزيد';
  return (
    <Card
      style={{
        width: '300px',
        height: '550px',
        borderRadius: '10px',
        flex: '0 0 auto',
      }}
    >
      <Card.Img
        variant='top'
        src={img}
        style={{ width: '300px', height: '400px' }}
      />
      <Card.Body
        style={{
          backgroundColor: '#090d2c',
          color: 'white',
          height: '100px',
          width: '300px',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Card.Title style={{ fontSize: '15px' }}>{name}</Card.Title>
        <Card.Text
          style={{
            direction: 'rtl',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            width: '260px',
            textAlign: 'right',
            height: '32px',
            fontSize: '11px',
          }}
        >
          {description}
        </Card.Text>
        <Link to={`tutors/${id}`}>
          <Button
            variant='success'
            style={{ backgroundColor: '#CBE4DE', color: 'black' }}
          >
            {BUTTON_TEXT}
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
}

export default TutorCard;
