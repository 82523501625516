import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Perk.css';
export default function Perk(props: {
  description: string;
  image: string;
  navto: string;
}) {
  const { description, image, navto } = props;
  const navigate = useNavigate();

  const scrollToComponent = () => {
    const element = document.getElementById(navto);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div
      className='perk'
      style={{ textAlign: 'center', marginBottom: '30px', padding: '3% 5%' }}
      onClick={() => scrollToComponent()}
    >
      <img
        src={image}
        style={{ width: '50px', height: '50px', marginBottom: '10px' }}
      ></img>

      <p style={{ marginBottom: '0' }}>{description}</p>
      <span style={{ color: '#0E8388' }}>learn more</span>
    </div>
  );
}
