import React from 'react';
import './Overview.css';
import Menu from './Menu';

export default function Overview() {
  const handleClick = () => {
    window.scrollBy({
      top: 650,
      behavior: 'smooth',
    });
  };
  return (
    <div>
      <div className='overview'>
        <div className='two-slogans'>
          <p dir='rtl'>أهلاً بيك في هجرة، إبدأ رحلتك دلوقتي!</p>
          <p dir='ltr'>Welcome to HEGRA, Start Your Journey now!</p>
        </div>
      </div>
    </div>
  );
}
