import React, { useState } from 'react';
import { tutorType } from '../types/tutorType';
import { levelType } from '../types/levelType';
import { Button, Form, Modal } from 'react-bootstrap';
import Rating from './Rating';
import './TutorInfo.css';
import ReactPixel from 'react-facebook-pixel';
import { AdvancedMatching } from 'react-facebook-pixel';

// This would typically be in your App.js or in individual components

const advancedMatching: AdvancedMatching = {
  em: '',
  ct: '',
  st: '',
  country: '',
  db: '',
  fn: '',
  ge: '',
  ln: '',
  ph: '',
  zp: '',
};

const options = {
  autoConfig: true, // set to false if you have a single-page application and you want to manually call `pageView`
  debug: false, // enable logs for debugging
};

ReactPixel.init('676401474684610', advancedMatching, options);

interface tutorInfoType {
  levels: levelType[];
  tutor: tutorType;
  selectedLevel: string;
}

const msg =
  'مرحبًا، أود أن أعرب عن اهتمامي بحجز دورة [اسم الدورة]. هل يمكنكم تزويدي بمزيد من التفاصيل حول تواريخ الدورة، الرسوم، وطريقة التسجيل؟ أنا متحمس جدًا للفرصة لتعلم وتطوير مهاراتي من خلال هذه الدورة. أود أيضًا معرفة إذا كان هناك أي متطلبات مسبقة للتسجيل أو مواد تحضيرية يجب علي الاطلاع عليها قبل بدء الدورة. شكرًا لكم مقدمًا على مساعدتكم وأتطلع بشوق لسماع المزيد من التفاصيل. مع خالص التقدير،';

export default function TutorInfo(props: tutorInfoType) {
  const { tutor, levels, selectedLevel: defaultSelectedLevel } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedLevel, setSelectedLevel] = useState(defaultSelectedLevel);
  const handleLevelChange = (e: any) => {
    const level = levels.find((level) => level.complexity == e.target.value);
    if (level != undefined) setSelectedLevel(level.id);
  };
  // const handleSendEmail = () => {
  //   ReactPixel.track('Lead', {
  //     value: 1,
  //     currency: 'USD',
  //     content_name: 'Book',
  //   });
  //   window.location.href = `mailto:mohamedgamea711@gmail.com?subject=Register&body=Hello, I would like to express my interest in booking the german course. Could you please provide me with more details about the course dates, fees, and registration process? Thank you `;
  // };

  const handleClickWhatsApp = () => {
    ReactPixel.track('Lead', {
      value: 1,
      currency: 'USD',
      content_name: 'Book',
    });
    // Specify the phone number and optionally a pre-filled message
    const phoneNumber = '+491782059890'; // Replace with the actual phone number
    const message = encodeURIComponent(
      'Hello, I would like to express my interest in booking the german course. Could you please provide me with more details about the course dates, fees, and registration process? Thank you'
    ); // Encode the message

    // Open WhatsApp chat in a new tab
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  // const handleClickInstagram = () => {
  //   ReactPixel.track('Lead', {
  //     value: 1,
  //     currency: 'USD',
  //     content_name: 'Book',
  //   });
  //   window.open('https://www.instagram.com/hegra.tech/');
  // };

  const selections = (
    <select className='level-select' dir='ltr' onChange={handleLevelChange}>
      {levels.map((level) => (
        <option key={level.id}>{level.complexity}</option>
      ))}
    </select>
  );

  return (
    <div
      className='tutor-info-container'
      style={{
        direction: 'rtl',
      }}
    >
      <div className='tutor-img-and-name'>
        <img src={tutor.img} alt='' />
        <div className='tutor-name-and-rating'>
          <div>{tutor.name}</div>
          <Rating rating={5} />
        </div>
      </div>
      <div className='tutor-description'>{tutor.description}</div>
      <div
        style={{
          color: '#090d2c',
          fontSize: '25px',
          fontWeight: '450',
        }}
      >
        {selections}
      </div>
      <div
        style={{
          color: '#090d2c',
          fontSize: '25px',
          fontWeight: '450',
        }}
      >
        السعر: &nbsp;
        {levels.find((level) => level.id == selectedLevel)?.price}
      </div>
      <div className='register-buttons'>
        <Button
          className='register-button'
          onClick={() => handleClickWhatsApp()}
        >
          التسجيل عن طريق Whatsapp
        </Button>
      </div>
    </div>
  );
}
