import React from 'react';
import './WhatIsHegra.css';
import Perk from './Perk';
import { Col, Row } from 'react-bootstrap';
import { Fields } from './fields';
import { useNavigate } from 'react-router-dom';
export default function () {
  let navigate = useNavigate();
  let width = window.innerWidth;
  function sortAlphabetically(arr: any) {
    let a = arr.sort((a: string, b: string) => a.localeCompare(b));
    console.log(a);
  }
  const perks1 = [
    {
      description: 'هنقدملك معلومات عن الاوراق المطلوبة لكل نوع تأشيرة',
      image: `${process.env.PUBLIC_URL}/images/icons8-dokumente-100.png`,
      navto: 'visas-container',
    },
    {
      description:
        'هنساعدك تقدم في جامعة مناسبة للمجال الي عايز تدرسه و هنقدملك معلومات عنها ',

      image: `${process.env.PUBLIC_URL}/images/icons8-universität-100.png`,
      navto: 'universities-component-container',
    },

    {
      description:
        'تقدر عندنا تحول نتيجة الثانوية العامة او الجامعة للمعدل الألماني',
      image: `${process.env.PUBLIC_URL}/images/icons8-taschenrechner-100.png`,
      navto: 'calulation-component-container',
    },
  ];

  const perks2 = [
    {
      description: 'تقدر تحجز عندنا كورس لغة في اي مستوي',

      image: `${process.env.PUBLIC_URL}/images/icons8-klassenzimmer-100.png`,
      navto: 'teachers-component-container',
    },

    {
      description:
        'لو انت مشترك في كورس تقدر تبعتلنا عن اي استفسار بخصوص السفر (الرد في خلال 48 ساعة)',
      image: `${process.env.PUBLIC_URL}/images/icons8-faq-100.png`,
      navto: 'teachers-component-container',
    },

    {
      description:
        'مش بس كدا، هنقدملك كمان مساعدات بعد ما تسافر في دراسة اللغة و وظائف الطلاب (coming soon)',
      image: `${process.env.PUBLIC_URL}/images/icons8-flugzeug-abflug-100.png`,
      navto: 'scroller-container',
    },
  ];

  return (
    <div
      className='wih-container'
      style={{
        textAlign: 'center',
      }}
      id='wih-container'
    >
      <h2 style={{ marginBottom: '20px' }}>؟HEGRA ايه هى </h2>
      <a href='https://www.instagram.com/p/C2QkY1PKAR3'>
        <button className='what-is-hegra-btn'>اتفرج على الفيديو</button>
      </a>
      <>
        <Row className='perks' dir='rtl'>
          {perks1.map((perk) => (
            <Col xs={12} md={4}>
              <Perk
                description={perk.description}
                image={perk.image}
                navto={perk.navto}
              ></Perk>
            </Col>
          ))}
        </Row>
        <Row dir='rtl'>
          {perks2.map((perk) => (
            <Col xs={12} md={4}>
              <Perk
                description={perk.description}
                image={perk.image}
                navto={perk.navto}
              ></Perk>
            </Col>
          ))}
        </Row>
      </>
    </div>
  );
}
