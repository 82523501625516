import { tutorType } from '../types/tutorType';

const tutors: tutorType[] = [
  {
    id: '1',
    name: 'امانى النمر',
    img: '/images/AmanyElnemr.PNG',
    description:
      ' أهلا أنا أماني النمر ، مدرسة لغة ألمانية .عندي خبرة 8 سنين في تعليم اللغة في أحسن المعاهد التعليمية في مصر هبدأ معك رحلتك من الصفر لحد ما توصل المستوى الي إنت عايزه وتبقى جاهز للدراسة او الشغل ',
    levels: ['1', '2', '3'],
    video: '/videos/AmanyElnemr.mp4',
  },
  {
    id: '2',
    name: 'مونيا محمد',
    img: '/images/MoniaMohamed.PNG',
    description:
      'اهلا بيكم أنا مونيا محمد ، مدرسة لغة ألمانية من 12 سنة و هكون إن شاء الله مدرستكم من المستوى الأول إلى المستويات المتقدمة من اللغة. اللغة الألمانية هي لغة مش صعبة و هتكون تجربتكم ممتعة و مفيده من خلال خبرتي في تدريس في أحسن معاهد مصر اللغة و باستعمال أحسن الكتب المعروفة في تدريس اللغة الالمانية. هستناكم إن شاء الله حتى نبدأ رحلتنا مع بعض. بالتوفيق!',
    levels: ['4', '5', '6'],
    video: '/videos/MoniaMohamed.mp4',
  },
  {
    id: '3',
    name: 'شيماء يوسف',
    img: '/images/ShaimaYoussef.jpeg',
    description:
      'اهلا بيكم انا شيماء يوسف خريجه السن دفعه ٢٠٠٣ انستركتور الماني خبره ١٧ سنه في التدريس بكون معاك خطوه بخطوه في تعليم اللغه الالمانيه من المستوى الاول الى الاحتراف  بنشتغل من كتب اكاديميه ممتازة في تعليم اللغه بجوانبها الاربعه  التحدث، الكتابه، الاستماع، والقراءة  بشتغل معاك/ي اثناء الكورسات على الجوانب الاربعه وخاصه التحدث باعتبارها اهم جزء في تعلم اللغه وده بيكون من السيشن الاولى ',
    levels: ['4', '5', '6'],
  },
];

export default tutors;
