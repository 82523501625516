import { Complexity, levelType } from "../types/levelType";

const levels: levelType[] = [
  {
    id: "1",
    price: 3250,
    complexity: Complexity.A1,
    numSubLevels: 2,
  },
  {
    id: "2",
    price: 3400,
    complexity: Complexity.A2,
    numSubLevels: 2,
  },
  {
    id: "3",
    price: 3600,
    complexity: Complexity.B1,
    numSubLevels: 2,
  },
  {
    id: "4",
    price: 4000,
    complexity: Complexity.A1,
    numSubLevels: 2,
  },
  {
    id: "5",
    price: 4250,
    complexity: Complexity.A2,
    numSubLevels: 2,
  },
  {
    id: "6",
    price: 4500,
    complexity: Complexity.B1,
    numSubLevels: 2,
  },
];

export default levels;
