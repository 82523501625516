export const visas = [
  {
    type: 'study',
    arabicName: 'تأشيرة الدراسة',
    require: [
      {
        req: 'جواز سفر صالح مع نسختين عنه',
        sub: [
          'الجواز ليس أقدم من عشر سنوات، ويحتوي على الأقل على صفحتين فارغتين وغير تالف.',
          'الجواز صالح لمدة ثلاثة أشهر بعد تاريخ العودة.',
          'حامل الجواز قد وقع على الجواز قبل تقديم الطلب.',
          'يمكن التعرف بوضوح على حامل الجواز من خلال صورة الجواز.',
          'نسخ من صفحة البيانات والصفحة 3.',
        ],
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'نموذج طلب التأشيرة الوطنية الالمانية',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'قبول جامعي',

        download: '',
        underline: 'ابحث عن جامعة',
        component: 'universities-component-container',
      },
      {
        req: 'شهادة لغة المانية',

        download: '',
        underline: 'احجز كورس',
        component: 'teachers-component-container',
      },
      {
        req: 'حساب بنكي مغلق بقيمة 11.208 يورو او تعهد لدى دائرة الأجانب في ألمانيا من قبل شخص بصفته الداعي',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'صورتين شمسيّتين بيومتريّتين (3,5 x 4,5 cm بخلفيّة فاتحة اللّون)',
        link: 'https://www.biometrisches-passbild.net/',
        download: '',
        underline: 'المزيد',
      },
      {
        req: 'نسختين عن تأمين صحي للسفر يغطي الفترة الممتدة من بداية صلاحية التّأشيرة حتى البدء بالدراسة (يُقدّم عند إصدار التّأشيرة)',
        link: 'https://static.tlscontact.com/media/eg/cai/de/insurance_list_in_ar_.pdf',
        download: '',
        underline: 'المزيد',
        sub: [
          'التأمين صالح لجميع دول شنجن.',
          'الحد الأدنى للتغطية هو ٣٠٬٠٠٠ يورو.',
          'لا توجد تغطية للعلاج.',
        ],
      },
      {
        req: 'السيرة الذاتية الخاصة بكم',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'الرسالة التحفيزية',
        link: '',
        download: '',
        underline: '',
      },
    ],
  },
  {
    type: 'work search',
    arabicName: 'تأشيرة البحث عن عمل',
    require: [
      {
        req: 'جواز سفر صالح مع نسختين عنه',
        sub: [
          'الجواز ليس أقدم من عشر سنوات، ويحتوي على الأقل على صفحتين فارغتين وغير تالف.',
          'الجواز صالح لمدة ثلاثة أشهر بعد تاريخ العودة.',
          'حامل الجواز قد وقع على الجواز قبل تقديم الطلب.',
          'يمكن التعرف بوضوح على حامل الجواز من خلال صورة الجواز.',
          'نسخ من صفحة البيانات والصفحة 3.',
        ],

        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'نموذج طلب التأشيرة الوطنية الالمانية',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'إثبات المؤهلات: شهادة جامعية مصدقة (أصلية ونسخة واحدة) وسجل الأداء (أصلي ونسخة واحدة)',
        link: '',
        download: '',
        underline: '',
        component: '',
      },
      {
        req: 'إثبات الاعتراف بالمؤهلات الأجنبية في حالة العمال المهرة الحاصلين على تدريب مهني: إشعار الاعتراف بالتدريب المهني الأجنبي: في حالة العمال المهرة الحاصلين على شهادة جامعية: نسخة مطبوعة من قاعدة بيانات anabin حول المؤهلات وعلى المؤسسة',
        link: '',
        download: '',
        underline: '',
        component: '',
      },
      {
        req: 'إثبات البحث عن عمل نشط: خطاب دعوة من شركة ألمانية لإجراء مقابلة عمل',
      },
      {
        req: 'إذا كان ذلك ممكنًا: إثبات التوظيف الحالي: خطاب الموارد البشرية لصاحب العمل الحالي (الأصلي)',
      },
      {
        req: 'شهادة لغة المانية',
        link: '',
        download: '',
        underline: 'احجز كورس',
        component: 'teachers-component-container',
      },
      {
        req: 'دليل على الوسائل المالية الكافية للبقاء في ألمانيا، يجب أن يكون لدى مقدم الطلب ما لا يقل عن 1.027 يورو شهريًا تحت تصرفه. يجب إثبات الأموال لمدة 6 أشهر على الأقل والتي لا تقل عن 6.162 يورو (في شكل حساب مصرفي مجمد أو تعهد رسمي من قبل شخص ثالث لتغطية جميع التكاليف).',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'صورتين شمسيّتين بيومتريّتين (3,5 x 4,5 cm بخلفيّة فاتحة اللّون)',
        link: 'https://www.biometrisches-passbild.net/',
        download: '',
        underline: 'المزيد',
      },
      {
        req: 'نسختين عن تأمين صحي للسفر يغطي الفترة الممتدة من بداية صلاحية التّأشيرة حتى البدء بالدراسة (يُقدّم عند إصدار التّأشيرة)',
        link: 'https://static.tlscontact.com/media/eg/cai/de/insurance_list_in_ar_.pdf',
        download: '',
        underline: 'المزيد',
        sub: [
          'التأمين صالح لجميع دول شنجن.',
          'الحد الأدنى للتغطية هو ٣٠٬٠٠٠ يورو.',
          'لا توجد تغطية للعلاج.',
        ],
      },
      {
        req: 'السيرة الذاتية الخاصة بكم',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'الرسالة التحفيزية',
        link: '',
        download: '',
        underline: '',
      },
    ],
  },
  {
    type: 'reunion',
    arabicName: 'تأشيرة لم الشمل',
    require: [
      {
        req: 'جواز سفر صالح مع نسختين عنه',
        sub: [
          'الجواز ليس أقدم من عشر سنوات، ويحتوي على الأقل على صفحتين فارغتين وغير تالف.',
          'الجواز صالح لمدة ثلاثة أشهر بعد تاريخ العودة.',
          'حامل الجواز قد وقع على الجواز قبل تقديم الطلب.',
          'يمكن التعرف بوضوح على حامل الجواز من خلال صورة الجواز.',
          'نسخ من صفحة البيانات والصفحة 3.',
        ],
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'صورتين شمسيّتين بيومتريّتين (3,5 x 4,5 cm بخلفيّة فاتحة اللّون)',
        link: 'https://www.biometrisches-passbild.net/',
        download: '',
        underline: 'المزيد',
      },
      {
        req: 'نموذج طلب التأشيرة الوطنية الالمانية',
        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'وثيقة زواج موثقة',
        sub: [
          'بالنسبة لوثيقة الزواج المصرية: يتعين تقديم وثيقة الزواج الأصلية المفصلة والتي تحتوي في صفحتها الأولى على الصور الشخصية للزوج والزوجة',
        ],
        link: '',
        download: '',
        underline: '',
        component: '',
      },
      {
        req: 'مستخرج رسمي حديث موثق من القيد العائلي (لا يرجع تاريخ اصداره الى أكثر من ثالثة أشهر)',
        link: '',
        download: '',
        underline: '',
        component: '',
      },
      {
        req: 'شهادة اثبات محل إقامة الزوج أو الزوجة في المانيا (لا يرجع تاريخ اصدارها إلى ما يزيد عن ثالثة أشهر)',
      },
      {
        req: 'بالنسبة لطلب االنضمام إلى الزوج/ الزوجة الذي/ التي لا يحمل / تحمل الجنسية الألمانية:',
        sub: [
          'صورة ضوئية من جواز السفر للزوج/ الزوجة المقيم/ المقيمة في المانيا (باستثناء الصفحات الخالية)',
          'صورة من تصريح/ كارت االقامة الحالي للزوج/ الزوجة في المانيا',
          ,
          'اثبات الدخل على سبيل المثال عقد العمل وبيان المرتب أو الدخل عن الثلاثة أشهر الأخيرة',
          'عقد إيجار الشقة الخاص بالزوج / الزوجة المقيم/ المقيمة في المانيا',
          'إذا كان أحد الزوجين قد سبق له الزواج: وثيقة الطلاق',
          'شهادة الإلمام باللغة الألمانية مستوى لغوي 1A من مستويات الاطار االوروربي المرجعي العام للغات والذي تقدمه جهة ممتحنة مصرح لها بتقديم الاختبار وفقا لمعايير ALTE',
        ],
      },

      {
        req: 'بالنسبة لطلب الانضمام إلى الزوج/ الزوجة الألماني/ الألمانية:',
        sub: [
          'صورة ضوئية من جواز السفر للزوج/الزوجة الألماني/الألمانية (باستثناء الصفحات الخالية)',
          'إذا كان أحد الزوجين أو كلاهما قد سبق له الزواج: وثيقة الطلاق',
          'شهادة الإلمام باللغة الألمانية مستوى لغوي A1 من مستويات الإطار الأوروبي المرجعي العام للغات والتي تقدمها جهة ممتحنة مصرح لها بتقديم الاختبار وفقًا لمعايير ALTE',
        ],

        link: '',
        download: '',
        underline: '',
      },
      {
        req: 'في حالة لم الشمل الى أحد الالجئين المعترف بهم',
        sub: [
          'قرار اللجوء من المكتب الاتحادي للهجرة واللاجئين (BAMF) بشأن الاعتراف بوضع الزوج/الزوجة اللاجئ/اللاجئة المقيم/المقيمة في ألمانيا',
          'إن وجد: إخطار حفظ المهلة',
        ],

        link: '',
        download: '',
        underline: '',
      },

      {
        req: 'نسختين عن تأمين صحي للسفر يغطي الفترة الممتدة من بداية صلاحية التّأشيرة حتى البدء بالدراسة (يُقدّم عند إصدار التّأشيرة)',
        link: 'https://static.tlscontact.com/media/eg/cai/de/insurance_list_in_ar_.pdf',
        download: '',
        underline: 'المزيد',
        sub: [
          'التأمين صالح لجميع دول شنجن.',
          'الحد الأدنى للتغطية هو ٣٠٬٠٠٠ يورو.',
          'لا توجد تغطية للعلاج.',
        ],
      },
    ],
  },
];
