import React from 'react';
import './App.css';
import LandingPage from './components/LandingPage';
import Menu from './components/Menu';

import WhatIsHegra from './components/WhatIsHegra';

import UniversitiesComponent from './components/UniversitiesComponent';
import CalculationComponent from './components/CalculationComponent';
import TeachersComponent from './components/TeachersComponent';
import Footer from './components/Footer';
import LogoScroller from './components/LogoScroller';
import Visas from './components/Visas';

function App() {
  return (
    <div>
      <Menu home={true} />
      <LandingPage></LandingPage>
      <WhatIsHegra />
      <Visas />
      <UniversitiesComponent />
      <CalculationComponent />
      <TeachersComponent />
      <LogoScroller />
      <Footer />
    </div>
  );
}

export default App;
