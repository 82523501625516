import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TutorInfo from './TutorInfo';
import './TutorPage.css';
import { useParams } from 'react-router-dom';
import tutors from './tutor';
import levels from './levels';
import { tutorType } from '../types/tutorType';
import { levelType } from '../types/levelType';
import Menu from './Menu';

export default function TutorPage() {
  const { id } = useParams();
  const [selectedLevel, setSelectedLevel] = useState('');
  const [tutor, setTutor] = useState<null | tutorType>(null);
  const [tutorLevels, setTutorLevels] = useState<null | levelType[]>([]);

  useEffect(() => {
    const selectedTutor = tutors.find((tutor) => tutor.id === id);
    if (selectedTutor === undefined) return;

    const filteredLevels = levels.filter((level) =>
      selectedTutor.levels.includes(level.id)
    );
    if (filteredLevels.length === 0) return;

    setTutor(selectedTutor);
    setTutorLevels(filteredLevels);
    setSelectedLevel(filteredLevels[0].id);
  }, [id, tutors, levels]);

  if (tutor === null || tutorLevels == null || tutorLevels.length === 0)
    return null;

  return (
    <div
      className='tutor-page-container'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
      }}
    >
      <Menu home={false} />

      <div className='tutor-page-inner-container'>
        <div className='tutor-video'>
          {!!tutor.video ? (
            <video
              src={tutor.video}
              controls
              style={{ borderRadius: '5px' }}
            ></video>
          ) : (
            <img src={tutor.img} width='640' height='360'></img>
          )}
        </div>
        <div className='tutor-profile'>
          <TutorInfo
            selectedLevel={selectedLevel}
            tutor={tutor}
            levels={tutorLevels}
          />
        </div>
      </div>
    </div>
  );
}

/** return (
    <Container
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
      }}
    >
      <Row style={{ marginTop: '4rem' }}>
        <Col sm='12' md='6' className='d-flex justify-content-center'>
          {!!tutor.video ? (
            <video src={tutor.video} width='640' height='360' controls></video>
          ) : (
            <img src={tutor.img} width='640' height='360'></img>
          )}
        </Col>
        <Col sm='12' md='6' className='d-flex justify-content-end'>
          <TutorInfo
            selectedLevel={selectedLevel}
            tutor={tutor}
            levels={tutorLevels}
          />
        </Col>
      </Row>
    </Container>
  ); */
