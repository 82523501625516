import React, { useEffect, useState } from 'react';
import { visas } from './VisasData';
import './Visas.css';

export default function Visas() {
  const [type, setType] = useState(visas[0].type);
  const [requirements, setRequirements] = useState<any>(visas[0].require);
  const handleChange = (event: any) => {
    setType(event.target.value);
  };

  const scrollToComponent = (nav: string) => {
    const element = document.getElementById(nav);
    console.log(nav);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  //   const getRec = (myType: string) => {
  //     setRequirements(
  //       visas.filter((visa) => visa.type == myType).map((visa) => visa.require)
  //     );
  //   };
  useEffect(() => {
    const filteredVisas = visas.find((visa) => visa.type === type);
    if (filteredVisas) {
      setRequirements(filteredVisas.require);
    }
  }, [type]);

  return (
    <div className='visas-container' id='visas-container'>
      <h3>الأوراق المطلوبة للتقديم علي تأشيرة</h3>
      <span>( المصدر: السفارة الألمانية بالقاهرة )</span>
      <select className='visa-select' value={type} onChange={handleChange}>
        {visas.map((visa) => (
          <option key={visa.type} value={visa.type}>
            {visa.arabicName}
          </option>
        ))}
      </select>
      <div className='visas-inner-component'>
        <ul className='visa-ul' dir='rtl'>
          {requirements.map((requirement: any, index: number) => (
            <li key={index}>
              {requirement.req} &nbsp;{' '}
              {requirement.component ? (
                <button
                  className='visa-btn'
                  onClick={() => scrollToComponent(requirement.component)}
                >
                  {requirement.underline}
                </button>
              ) : (
                <a href={requirement.link} target='_blank'>
                  {requirement.underline}
                </a>
              )}
              {requirement.sub ? (
                <ul>
                  {requirement.sub.map((sub: any, index: number) => (
                    <li key={index}>{sub}</li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
