import React, { useState } from 'react';
import { Form, Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';

const CalculationForm: React.FC = () => {
  const [input1, setInput1] = useState<number>(0);
  const [input2, setInput2] = useState<number>(0);
  const [input3, setInput3] = useState<number>(0);
  const [result, setResult] = useState<number | null>(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedProgram, setSelectedProgram] = useState<string | null>(
    'هندسة'
  );
  const handleSelect = (eventKey: React.SetStateAction<string | null>) => {
    setSelectedProgram(eventKey);
  };

  const handleCalculation = () => {
    handleShow();
    const calculationResult = 4 - ((input3 - input1) / (input2 - input1)) * 3;
    setResult(Math.round(calculationResult * 100) / 100);
  };
  const calculateFeedBackMsg = (result: number) => {
    if (selectedProgram == 'هندسة' || selectedProgram == 'برمجة') {
      if (result < 2.5)
        return 'تستطيع ان تحصل على قبول جامعى فى اغلب الجامعات فى المانيا بهذا المعدل';
      return 'يمكن ان تجد بعد الصعوبات لتحصل على قبول جامعى بهذا المعدل';
    } else if (selectedProgram == 'مجال طبى') {
      if (result < 1.2)
        return 'لديك فرصة عالية فى الحصول على قبول جامعى بهذا المعدل';
      else if (result < 1.5)
        return 'من الممكن الحصول على قبول جامعى فى المجال الطبى بهذا المعدل ولكن الفرص ليست عالية';
      return 'من الصعب جدا ان تحصل على قبول جامعى فى المجال الطبى بهذا المعدل';
    }
  };

  return (
    <div style={{ ...styles.formContainer, direction: 'rtl' }}>
      <style>{customStyles}</style>

      <Form>
        <Form.Group controlId='input1'>
          <Form.Label>اقل درجة للنجاح</Form.Label>
          <Form.Control
            type='number'
            value={input1}
            onChange={(e) => setInput1(parseFloat(e.target.value))}
          />
        </Form.Group>

        <Form.Group controlId='input2'>
          <Form.Label>اقصى درجة ممكن الحصول عليها</Form.Label>
          <Form.Control
            type='number'
            value={input2}
            onChange={(e) => setInput2(parseFloat(e.target.value))}
          />
        </Form.Group>

        <Form.Group controlId='input3'>
          <Form.Label>الدرجة التى حصلت عليها</Form.Label>
          <Form.Control
            type='number'
            value={input3}
            onChange={(e) => setInput3(parseFloat(e.target.value))}
          />
        </Form.Group>

        <Form.Group controlId='input3' style={{ marginTop: '0.5rem' }}>
          <Form.Label>التخصص</Form.Label>

          <DropdownButton
            style={{
              display: 'inline',
              margin: '0.5rem',
            }}
            className='custom-dropdown-button'
            title={selectedProgram}
            onSelect={handleSelect}
            variant='dark'
          >
            <Dropdown.Item eventKey='هندسة'>هندسة</Dropdown.Item>
            <Dropdown.Item eventKey='برمجة'>برمجة</Dropdown.Item>
            <Dropdown.Item eventKey='مجال طبى'>مجال طبى</Dropdown.Item>
          </DropdownButton>
        </Form.Group>

        <Button
          variant='primary'
          onClick={handleCalculation}
          style={{
            marginTop: '0.5rem',
            background: '#fabd03',
            border: 'none',
            color: '#090d2c',
          }}
        >
          احسب
        </Button>

        {result !== null && !isNaN(result) && (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>تم حساب المعدل بالألماني</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ direction: 'rtl' }}>
              <p>معدلك بالألماني هو:</p>
              <h2>{result}</h2>
              <p>{calculateFeedBackMsg(result)}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                اغلاق
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Form>
    </div>
  );
};

export default CalculationForm;

const customStyles = `
    .custom-dropdown-button .btn {
      padding:0 0.25rem;
    }
  `;
const styles = {
  formContainer: {
    width: '100%',
    color: '#fff',
    padding: '20px',

    borderRadius: '5px',
    backgroundColor: '#090d2c',
    boxShadow:
      '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
  },
  resultContainer: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#2a9d92',
    borderRadius: '5px',
  },
  heading: {
    textAlign: 'center',
  },
  paragraph: {
    margin: '0',
    fontSize: '18px',
  },
};
