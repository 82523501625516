import React from 'react';
import './TeachersComponent.css';
import Rating from './Rating';
import tutors from './tutor';
import { Link } from 'react-router-dom';

const TeacherComponentCard = ({
  name,
  rating,
  imgLink,
  link,
  description,
  id,
}: any) => {
  return (
    <div className='teacher-component-card'>
      <div className='teacher-name-and-img'>
        <img src={imgLink}></img>
        <h2 dir='rtl'>{name}</h2>
      </div>
      <div className='teacher-rating'>
        <label style={{ color: '#fff' }}>Rating:</label>
        <Rating rating={rating}></Rating>
      </div>
      <p dir='rtl'>{description.slice(0, 160) + '...'}</p>
      <div className='teacher-buttons'>
        <Link to={`tutors/${id}`}>
          <button className='teacher-btn'>اعرف المزيد</button>
        </Link>
      </div>
    </div>
  );
};
export default function TeachersComponent() {
  return (
    <div
      className='teachers-component-container'
      id='teachers-component-container'
    >
      <h1 className='teachers-component-title'>إحجز كورس ألماني معانا</h1>
      <div className='courses-filter'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: '#fff',
            alignItems: 'end',
            gap: '10px',
          }}
        >
          <label>المستوي</label>
          <select>
            <option>A1</option>
            <option>A2</option>
            <option>B1</option>
            <option>B2</option>
            <option>C1</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: '#fff',
            alignItems: 'end',
            gap: '10px',
          }}
        >
          <label>بداية الكورس</label>
          <select>
            <option>يناير</option>
            <option>فبراير</option>
            <option>مارس</option>
            <option>ابريل</option>
            <option>مايو</option>
            <option>يونيو</option>
            <option>يوليو</option>
            <option>أغسطس</option>
            <option>سبتمبر</option>
            <option>اكتوبر</option>
            <option>نوفمبر</option>
            <option>ديسمبر</option>
          </select>
        </div>
      </div>
      <div
        className='teachers-component-inner-container'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
        }}
      >
        {tutors.map((tutor) => (
          <TeacherComponentCard
            key={tutor.id}
            name={tutor.name}
            imgLink={tutor.img}
            description={tutor.description}
            rating={5}
            id={tutor.id}
          />
        ))}
      </div>
    </div>
  );
}
