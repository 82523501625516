import React from 'react';
import Menu from './Menu';
import './Application.css';
import { Button } from 'react-bootstrap';

export default function Application() {
  const handleClickWhatsApp = () => {
    // Specify the phone number and optionally a pre-filled message
    const phoneNumber = '+491782059890'; // Replace with the actual phone number
    const message = encodeURIComponent(''); // Encode the message

    // Open WhatsApp chat in a new tab
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  const listItems = [
    {
      text: 'بيان درجات الثانوية العامة موثق من وزارة الخارجية و مترجم من مترجم معتمد و موثق من السفارة الألمانية',
      links: [
        {
          href: 'https://www.elwatannews.com/news/details/7032826#:~:text=%D8%A7%D8%B3%D8%AA%D8%AE%D8%B1%D8%A7%D8%AC%20%D8%A8%D9%8A%D8%A7%D9%86%20%D9%86%D8%AC%D8%A7%D8%AD%20%D8%AB%D8%A7%D9%86%D9%88%D9%8A%D8%A9%20%D8%B9%D8%A7%D9%85%D8%A9%20%D8%A3%D9%88%D9%86%20%D9%84%D8%A7%D9%8A%D9%86&text=1%2D%20%D8%A7%D9%84%D8%AF%D8%AE%D9%88%D9%84%20%D9%84%D9%85%D9%88%D9%82%D8%B9%20%D8%A7%D9%84%D9%85%D8%AF%D9%8A%D8%B1%D9%8A%D8%A9%20%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85%D9%8A%D8%A9,4%2D%20%D9%83%D8%AA%D8%A7%D8%A8%D8%A9%20%D8%A7%D9%84%D8%B5%D9%81%20%D8%A7%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D9%8A%20%D9%84%D9%84%D8%B7%D8%A7%D9%84%D8%A8.',
          name: 'خطوات استخراج بيان الدرجات',
        },
      ],
    },
    {
      text: 'بيان درجات الجامعة (في حال اكمال الدراسة او عدم اكمالها مطلوب بيان درجات السنوات المقضية في الجامعة) موثق من وزارة الخارجية و مترجم من مترجم معتمد و موثق من السفارة الألمانية',
      links: [
        {
          href: 'https://kairo.diplo.de/blob/1505052/fc37799ecdf35dfa75dd59763d6d2383/uebersetzerliste--ara--data.pdf',
          name: 'قائمة المترجمين المعتمدين',
        },
        {
          href: 'https://kairo.diplo.de/eg-ar/service/-/1516462',
          name: 'التوثيق من السفارة الألمانية',
        },
      ],
    },
    {
      text: 'شهادة اجتياز امتحان اللغة الألمانية (مستوي B1 علي الأقل للحصول علي قبول مشروط او شهادة TestDAF او TelcC1 للحصول علي قبول نهائي) من معهد جوته او معهد داخلي معترف به من السفارة الألمانية',
      links: [],
    },
    {
      text: 'صورة جواز السفر',
      links: [],
    },
    {
      text: 'خطاب ترشيحي (إن وجد)',
      links: [],
    },
  ];

  return (
    <div className='application-container'>
      <Menu home={false} />
      <h2>الأوراق المطلوبة للتقديم</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ul dir='rtl' className='fancyList'>
          {listItems.map((item, index) => (
            <li key={index} className='fancyItem'>
              {item.text}

              {item.links.length > 0 &&
                item.links.map((link, index) => (
                  <>
                    &nbsp; &nbsp;
                    <a target='blank' href={link.href} key={index}>
                      {link.name}
                    </a>
                  </>
                ))}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '15px',
        }}
      >
        في حال استيفائكم لجميع المستندات المطلوبة يرجي التواصل معنا لبدأ عملية
        التقديم
        <Button
          style={{
            marginBottom: '20px',
            width: '200px',
            height: '50px',
            fontSize: '20px',
          }}
          onClick={() => handleClickWhatsApp()}
        >
          تواصل معنا الآن
        </Button>
      </div>
    </div>
  );
}
